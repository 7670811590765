import {useBackToHomeLink} from '~/hooks'
import {cs} from '~/utils'
import type {HtmlElProps} from '~/types'
import {OffersLogoLink} from '../offers-logo'
import {BackButton} from './back-button'

type BaseProps = Omit<HtmlElProps, 'color'> & {
  /** Whether to render the button or the Offers logo */
  variant?: 'button' | 'offers-logo'
}

type BasePropsWithFileId = BaseProps & {
  /** The file ID to go back to. Null will use file ID from the current URL query. */
  fileId?: string | number | null | undefined
}

type BasePropsWithCustomLink = BaseProps & {
  /** The overriding href to direct to the user to when they click the back button */
  backHref: string
  /** OPTIONAL: The overriding text to display on the back button */
  backText?: string
}

export type BackToHomeBtnBtnProps = BasePropsWithFileId | BasePropsWithCustomLink

/**
 * A link that will direct the user back to either the listings
 * dash or their forms file depending on the value of the
 * isFormsUserAtom.
 *
 * Either a button with a back arrow or the OM logo is rendered
 * depending on the `variant` prop. Both are intended to be
 * placed in the top header bar of the app.
 */
export function BackToHomeBtn(props: BackToHomeBtnBtnProps) {
  const {
    className: _className,
    fileId,
    backHref: _backHref,
    backText: _backText,
    variant = 'button',
    ...rest
  } = props as BasePropsWithFileId & BasePropsWithCustomLink

  const {backText, backHref} = useBackToHomeLink({
    fileId,
    backTextOverride: _backText,
    backHrefOverride: _backHref,
  })

  const className = cs('BackToHomeBtn', _className)

  return (
    <>
      {variant === 'button' && (
        <BackButton {...rest} className={className} label={backText} href={backHref}>
          {backText}
        </BackButton>
      )}
      {variant === 'offers-logo' && <OffersLogoLink href={backHref} />}
    </>
  )
}
