import Head from 'next/head'
import {SkySlopeAddress} from '@skyslope/utils'

import {BackToHomeBtn, GoBackHeader, HeaderUserSettings} from '~/components'
import type {HtmlElProps, Listing} from '~/types'
import {cs} from '~/utils'

type BaseProps = HtmlElProps & {
  classes?: {
    root?: string
    childWrapper?: string
  }
  documentTitle?: string
  variant?: 'logo' | 'go-back'
}

type LogoVariantProps = {
  logoHref?: string
}

type GoBackVariantProps = {
  /** Address to display as the default `goBackTitle` if variant is go-back */
  listing?: Listing
  /** Text to display at the top of the page if variant is go-back.  Default is listing address (if listing is passed) */
  goBackTitle?: string
  /** The overriding href to direct to the user to when they click the back button */
  goBackHref: string
  /** OPTIONAL: The overriding text to display on the back button */
  goBackButtonText?: string
  /** If true, a vertical divider will be placed between the top left content and the page title */
  showGoBackTopLeftSeparator?: boolean
}

type Disallow<T> = {[K in keyof T]?: never} & {}

export type NoGradientLayoutProps = BaseProps &
  (
    | ({variant?: 'logo' | undefined} & LogoVariantProps & Disallow<GoBackVariantProps>)
    | ({variant: 'go-back'} & GoBackVariantProps & Disallow<LogoVariantProps>)
  )

export function NoGradientLayout(props: NoGradientLayoutProps) {
  let {
    children,
    className,
    listing,
    goBackTitle,
    goBackButtonText,
    goBackHref,
    showGoBackTopLeftSeparator,
    classes,
    logoHref,
    documentTitle = 'SkySlope - Offers',
    variant = 'logo',
    ...rest
  } = props

  return (
    <div {...rest} className={cs('NoGradientLayout', classes?.root, className)}>
      <Head>
        <title>{documentTitle}</title>
      </Head>
      <GoBackHeader
        title={
          variant === 'logo'
            ? undefined
            : goBackTitle || SkySlopeAddress.formatLong(listing?.property)
        }
        backBtn={
          <BackToHomeBtn
            fileId={listing?.fileId}
            backText={goBackButtonText}
            backHref={variant === 'logo' ? logoHref : goBackHref}
            variant={variant === 'logo' ? 'offers-logo' : 'button'}
          />
        }
        classes={{
          backBtnSeparator: showGoBackTopLeftSeparator ? undefined : 'border-l-0',
        }}
        rightSideContent={<HeaderUserSettings />}
      />
      <div
        className={cs(
          'NoGradientLayout.childWrapper mx-auto max-w-screen-xl',
          classes?.childWrapper,
        )}
      >
        {children}
      </div>
    </div>
  )
}
