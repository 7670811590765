import Link from 'next/link'
import {SrOnly} from '@skyslope/mache'

import {useBackToHomeLink} from '~/hooks'
import LogoImage from 'public/static/images/ss-apps/om-logo.svg'

export function OffersLogoLink(props: {href?: string}) {
  const {backHref, backText} = useBackToHomeLink({
    fileId: null,
    backHrefOverride: props.href,
  })

  const logo = <LogoImage width={130} height={34} />
  if (!backHref) return logo

  return (
    <Link href={backHref}>
      <a>
        <SrOnly>{backText}</SrOnly>
        {logo}
      </a>
    </Link>
  )
}
