import {type AxiosError} from 'axios'
import {useQuery, type UseQueryOptions} from 'react-query'

import {getPublicListing, GetPublicListingPayload, GetPublicListingPayloadIntersection} from '~/api'
import type {PublicListing} from '~/types'
import {ErrorCodes, type QueryDefinition} from '~/utils'
import {useErrorReporter} from '../use-error-reporter'

export const publicListingKey = 'get-listing'

/** Fetch public Listing for the Buyer's Agent */
export function useGetPublicListing(
  payload: GetPublicListingPayload,
  options: UseQueryOptions<PublicListing, AxiosError> = {},
) {
  const {reportError} = useErrorReporter(ErrorCodes.GetPublicListing)
  const {queryKey, queryFn} = getPublicListingQuery(payload)
  const _payload = payload as GetPublicListingPayloadIntersection

  return useQuery<PublicListing, AxiosError>(queryKey, queryFn, {
    enabled: !!_payload.linkId || !!(_payload.market && _payload.mlsListingId),
    ...options,
    onError(e) {
      options?.onError?.(e)
      reportError(e, {snackbar: false})
    },
    retry: false,
  })
}

export function getPublicListingQuery(payload: GetPublicListingPayload): QueryDefinition {
  const _payload = payload as GetPublicListingPayloadIntersection
  return {
    queryKey: [
      publicListingKey,
      _payload.linkId ?? null,
      _payload.offerId ?? null,
      _payload.market ?? null,
      _payload.mlsListingId ?? null,
    ],
    queryFn: () => getPublicListing(payload),
    options: {retry: false},
  }
}
