import {useEffect, useState} from 'react'
import {LoaderButton} from '@skyslope/mache'
import Head from 'next/head'

import {getUserManager, useAuthStore} from '~/auth'

import {NoGradientLayout} from '~/page-components'
import {config} from '~/utils'
import {useOktaWidget, useRouter} from '~/hooks'

export default function Home() {
  const authStore = useAuthStore()
  const {router, routes} = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const widgetAnchorRef = useOktaWidget()

  useEffect(() => {
    if (config.env === 'prod') router.push(routes.seller.listingDash())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleLogout() {
    setIsLoading(true)
    await getUserManager().startLogout()
    setIsLoading(false)
    authStore.reset()
  }

  if (config.env === 'prod') return null

  if (authStore.loggedIn)
    return (
      <NoGradientLayout
        classes={{
          root: 'Home h-full flex flex-col',
          childWrapper: 'flex flex-1 flex-col justify-center text-left p-4 pb-28',
        }}
      >
        <h1 className="mb-8 text-3xl font-bold">Offer Management</h1>

        <div>
          Logged in as {authStore.userInfo.email}
          <LoaderButton
            className="ml-4"
            variant="contained"
            color="primary"
            onClick={handleLogout}
            isLoading={isLoading}
          >
            Logout
          </LoaderButton>
        </div>
        <BottomInfo />
      </NoGradientLayout>
    )

  return (
    <div className="mx-auto mt-32 flex flex-col items-center">
      <Head>
        <title>SkySlope - Offers</title>
      </Head>
      <div ref={widgetAnchorRef} id="auth-widget-anchor" className="min-h-[625px]" />
      <BottomInfo />
    </div>
  )
}

function BottomInfo() {
  return (
    <p className="mt-8 text-grey-800">
      This login page is for internal use and is disabled in production.
      <br />
      Current environment: {config.env}
    </p>
  )
}
