import {useAtomValue} from 'jotai'
import {isFormsUserAtom} from '~/state'
import {useAuthHelpers} from './auth'
import {useRouter} from './use-router'
import {useGetPublicListing} from './_queries'

/**
 * Get the text and href for a link that takes this user back
 * to their "home base" in our app.
 *
 * Depending on the user, that is either back to their Forms
 * file (Forms user) or the Offers listings dash (all other users).
 */
export function useBackToHomeLink(args: {
  fileId: number | string | undefined | null
  backTextOverride?: string | undefined
  backHrefOverride?: string | undefined
}) {
  const {fileId: _fileId, backTextOverride, backHrefOverride} = args

  const {authState} = useAuthHelpers()
  const {routes, query} = useRouter()

  const {data: listing} = useGetPublicListing({linkId: query.linkId}, {enabled: !!query.linkId})

  const isFormsUser = useAtomValue(isFormsUserAtom)
  const fileId = String(_fileId ?? query.fileId ?? query.buyerFileId ?? listing?.fileId)
  const goToForms = isFormsUser && fileId

  const backText = goToForms ? 'Back to File' : authState === 'logged-in' ? 'Back to Listings' : ''
  const backHref = goToForms
    ? routes.external.formsFile({fileId})
    : authState === 'logged-in'
      ? routes.seller.listingDash()
      : ''

  return {
    backText: backTextOverride || backText,
    backHref: backHrefOverride || backHref,
  }
}
