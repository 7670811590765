import {AppEnvironment, config} from './env-constants'

/**
 * This utility simplifies the logic of checking which environment
 * (local, integ, staging, prod) we are currently in.
 *
 * You can pass multiple environments as multiple parameters
 * to this function, and it will return true if we are currently
 * in any of those environments.
 */
export function isEnv(...env: AppEnvironment[]) {
  return env.includes(config.env)
}
