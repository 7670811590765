import axios from 'axios'
import {type UnionToIntersection} from '@skyslope/utils'

import {config, stupidDateManipulator} from '~/utils'
import type {PublicListing} from '~/types'
import {userManager} from '~/auth'

export type GetPublicListingPayload =
  | {
      linkId: string
      offerId?: string
    }
  | {
      market: string
      mlsListingId: string
      offerId?: string
    }

export type GetPublicListingPayloadIntersection = Partial<
  UnionToIntersection<GetPublicListingPayload>
>

export async function getPublicListing(payload: GetPublicListingPayload): Promise<PublicListing> {
  const token = await userManager?.getAccessToken()
  const maybeHeaders = token ? {headers: {authorization: `Bearer ${token}`}} : undefined

  const res = await axios.post<PublicListing>(
    `${config.apiBaseUrl}/listings/public`,
    payload,
    maybeHeaders,
  )
  const publicListing = res.data
  publicListing.offers?.forEach(stupidDateManipulator.apiToUi)
  return publicListing
}
