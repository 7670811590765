import {forwardRef, type ForwardedRef} from 'react'
import Link from 'next/link'
import {Button, type ButtonProps} from '@mui/material'
import {ArrowBack} from '@mui/icons-material'

import {useAtom} from 'jotai'
import {cs} from '~/utils'
import {previousUrlAtom} from '~/state'
import {useRouter} from '~/hooks'

export type BackButtonProps = Omit<ButtonProps, 'onClick'> & {
  label?: string
  href?: string
}

/**
 * A link-button with a back arrow intended to be placed in the
 * top header bar of the app.  If no href is passed, this button
 * acts like the browser back button.
 */
export const BackButton = forwardRef(function BackButton(
  props: BackButtonProps,
  ref: ForwardedRef<any>,
) {
  const {href, label, ...rest} = props

  let finalHref = href
  let finalLabel = label

  const {router, routes, query} = useRouter()
  const [previousUrl] = useAtom(previousUrlAtom)

  if (query.backTo) {
    finalLabel = 'Back'
    finalHref = query.backTo
  } else if (!finalHref && !previousUrl && typeof history !== 'undefined' && history.length <= 1) {
    // If we can be relatively certain that router.back() will be a no-op because
    // the user's history is empty, then we make this a "Go To Home" button instead.
    // This can happen when the user is linked directly to a page with a back button
    // from an email or something (e.g. the offer details page).
    finalLabel = 'Back to Listings'
    finalHref = routes.seller.listingDash()
  }

  return finalHref ? (
    <Link href={finalHref} passHref>
      <TheButton {...rest} ref={ref} component="a" label={finalLabel} />
    </Link>
  ) : (
    <TheButton {...rest} ref={ref} onClick={() => router.back()} label={finalLabel} />
  )
})

const TheButton = forwardRef(function TheButton(
  props: ButtonProps & {label?: string; component?: string},
  ref: ForwardedRef<any>,
) {
  const {className, label = 'Back', ...rest} = props

  return (
    <Button
      {...rest}
      ref={ref}
      className={cs('BackButton', 'pl-3 pr-4', className)}
      startIcon={<ArrowBack />}
    >
      {label}
    </Button>
  )
})
