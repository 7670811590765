import {useEffect, useRef} from 'react'
import {useAuth} from '@skyslope/auth-react'

import {getUserManager} from '~/auth'
import {config, isEnv} from '~/utils'

/**
 * This hook initializes the Okta widget on whatever page the passed in Ref is on.
 * The ref that is passed in can be customized, allowing for different use cases on different pages
 * @param widgetAnchorRef Ref that the widget will attach to
 */
export function useOktaWidget() {
  const userManager = getUserManager()
  const {authState} = useAuth()
  const widgetAnchorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isEnv('prod')) return

    let widget: any
    async function initWidget() {
      const initSignInWidget = await (await import('@skyslope/auth-sign-in-widget')).default
      widget = initSignInWidget({
        anchorEl: widgetAnchorRef.current!,
        viewOptions: {
          applicationNameOverride: 'SkySlope Offers',
          showSignInWithSkySlopeBtn: true,
          logoChoice: 'Offers',
          useOptionalLinks: true,
        },
        authConfigOptions: {
          userManager: userManager,
          redirectAfterPasswordResetUrl: window.location.origin,
          idpDiscovery: {
            requestContext: config.okta.requestContext,
          },
        },
      })
    }
    if (widgetAnchorRef.current && !authState.isAuthenticated) {
      initWidget()
    }
    return () => widget?.remove()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return widgetAnchorRef
}
