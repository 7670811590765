//
//
// THIS FILE IS FILLED WITH NONSENSE THAT TRIES TO FIX DATE FORMAT
// INCONSISTENCIES BETWEEN THE API AND THE UI.
//
//

import {dateOnly, UnionToIntersection, Nullable} from '@skyslope/utils'

import type {Offer, OfferLeaseTerms, OfferSaleTerms, PublicOffer} from '~/types'

// ===============================
// DO ALL OF THE STUPID DATE MANIPULATIONS
// ===============================

type OfferToManipulate = Nullable<Partial<Offer | PublicOffer>>

export const stupidDateManipulator = {
  apiToUi(offer: OfferToManipulate) {
    if (!offer) return
    stripTimeFromPlainDateFields(offer)
  },
}

// ===============================
// STRIP INCORRECT TIME FROM DATE FIELDS
//
// These dates are stored as ISO Date-Times in the API, but are meant to
// represent a date with no time value.
// ===============================

// Strongly type the contents of `offerTermDateFieldList` to always include every
// key in `OfferTerms` whose value is of type `LocalDate`.
// See: https://stackoverflow.com/a/75481039/2517147
const offerTermDateFieldList = getLocalDateKeyList<OfferSaleTerms>({
  closingDate: true,
  loanApprovalDate: true,
  inspectionDate: true,
  irrevocabilityDate: true,
  offerExpiration: true,
})
const leaseTermDateFieldList = getLocalDateKeyList<OfferLeaseTerms>({
  moveInDate: true,
  irrevocabilityDate: true,
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getLocalDateKeyList<T>(includeObj: Record<string, true>) {
  return Object.entries(includeObj).reduce((accum, [dateField, doInclude]) => {
    if (doInclude) accum.push(dateField)
    return accum
  }, [] as string[])
}

/**
 * Remove the time zone information from all offer local date
 * fields and turn them into "local dates" by transforming
 * `2020-02-01T00:00:00Z` into `2020-02-01`.
 *
 * The OfferTerms LocalDate fields are stored as ISO Dates in the
 * API, but are meant to represent a date in "local time", meaning
 * the local time zone of whoever is reading it.
 *
 *
 * THIS FUNCTION MUTATES THE OFFER
 */
function stripTimeFromPlainDateFields(offer: OfferToManipulate) {
  function stripTheseDateFields<T extends Record<string, any>>(
    terms: T,
    dateFieldList: Array<keyof T>,
  ) {
    for (const dateField of dateFieldList) {
      const thisDate = terms[dateField] as string | undefined
      if (!thisDate) continue
      terms[dateField] = dateOnly(thisDate) as any
    }
  }

  if (!offer) return
  const o = offer as UnionToIntersection<Offer | PublicOffer>
  if (o.offerTerms)
    stripTheseDateFields(o.offerTerms, offerTermDateFieldList as Array<keyof OfferSaleTerms>)
  if (o.leaseTerms)
    stripTheseDateFields(o.leaseTerms, leaseTermDateFieldList as Array<keyof OfferLeaseTerms>)
}
